import { seoProperties } from '@/helpers/seo';
import SplashProvider from '@/providers/splash';
import ThemeProvider from '@/providers/theme';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { NextSeo } from 'next-seo';
import dynamic from 'next/dynamic';
import '../baseline/styles.scss';

const App = dynamic( () => import( './_app.component' ), { ssr: false } );

function _App( props: any ) {
	const seoData = seoProperties[ props.router.pathname ];
	const emotionCache = createCache( { key: 'css', prepend: true } );
	
	return (
		<CacheProvider value={emotionCache}>
			<ThemeProvider>
				<SplashProvider>
					<NextSeo
						title={seoData?.title ?? 'Invoiss, Free cloud-based accounting software'}
						defaultTitle='Invoiss, Free cloud-based accounting software'
						description={seoData?.description ?? undefined}
					/>
					<App {...props} emotionCache={emotionCache}/>
				</SplashProvider>
			</ThemeProvider>
		</CacheProvider>
	);
}

export default _App;
